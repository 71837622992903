import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions, Router } from '@angular/router';
import { route } from 'aimmo-core2/app/app.route.path';
import { environment } from 'aimmo-core2/environments/environment';
import { getAimmoCoreHomepageUrl } from 'aimmo-core2/environments/environment.interface';
import { defer, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private redirectUrl: string | null;

  constructor(
    private router: Router
  ) {
  }

  public routeToRedirectUrlIfExist(replaceUrl = false): Observable<boolean> {
    const redirectUrl = this.redirectUrl;
    this.redirectUrl = null;
    if (redirectUrl) {
      return this.routeByUrl(redirectUrl, { replaceUrl });
    } else {
      return this.routeToHome(replaceUrl);
    }
  }

  public routeByUrl(url: string, extras: NavigationBehaviorOptions = { replaceUrl: false }): Observable<boolean> {
    return defer(() => this.router.navigateByUrl(url, extras));
  }

  public routeToHome(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.home.fullPath], { replaceUrl }));
  }

  public routeToAuthentication(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.authentication.fullPath], { replaceUrl }));
  }

  public routeToAuthenticationExpired(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.authenticationExpired.fullPath], { replaceUrl }));
  }

  public setRedirectUrl(redirectUrl: string): void {
    this.redirectUrl = redirectUrl;
  }

  public routeToSubscriptionActivate(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.subscriptionActivate.fullPath], { replaceUrl }));
  }

  public routeToDashboard(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.dashboard.fullPath], { replaceUrl }));
  }

  public routeToWorkspaceError(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.workspaceError.fullPath], { replaceUrl }));
  }

  public routeToLogin(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.login.fullPath], { replaceUrl }));
  }

  public routeToSignUpCompletion(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.signUp.children.signUpCompletion.fullPath], { replaceUrl }));
  }

  public routeToNewWorkspace(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.newWorkspace.fullPath], { replaceUrl }));
  }

  public routeToSignUpTerms(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.signUp.children.terms.fullPath], { replaceUrl }));
  }

  public routeToSignUpTermsByInvitation(replaceUrl = false): Observable<boolean> {
    return defer(() => this.router.navigate([route.signUp.children.termsForInvitation.fullPath], { replaceUrl }));
  }

  public routeToAimmoCoreHomepage(): void {
    window.location.href = getAimmoCoreHomepageUrl(environment.phase);
  }
}
